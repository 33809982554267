<template>
  <div class="container mt-5 d-flex justify-content-center">
    <div class="card w-30">
      <div class="card-body">
        <div class="mb-4 text-center">
          <h5 class="text-center text-white mb-3">Login with</h5>
          <button
            @click="loginWithGithub"
            class="btn btn-dark w-30 border border-secondary"
          >
            <i class="fab fa-github me-2"></i> GitHub Account
          </button>
        </div>

        <div class="text-center text-muted mb-4">
          <strong>or</strong>
        </div>

        <div>
          <h5 class="text-center text-white mb-3">Test with Public Signaling</h5>
          <div class="d-flex justify-content-center">
            <input
              type="text"
              class="form-control bg-dark text-white border-secondary w-30 text-center"
              :value="mqttUrl"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import backend from '@/backend';
export default {
  data() {
    return {
      mqttUrl: 'mqtts://libpeer.com/public',
    };
  },
  methods: {
    loginWithGithub() {
      window.location.href =
        'https://github.com/login/oauth/authorize?client_id=e466b5b681f2f5a1231e&redirect_uri=' + window.location.origin + '/login&scope=user'
    },
    async handleGithubCallback(code) {
      try {
        console.log('gogo');
        const response = await backend.post('/auth/github?code=' + code);
        console.log(response.data);

        this.$router.push("/");
      } catch (err) {
        this.error = err.response?.data?.detail || "Authentication failed.";
      }
    },
  },
  created() {
    const code = new URL(window.location.href).searchParams.get("code");
    if (code) {
      this.handleGithubCallback(code);
    } else {
//      const response = backend.get('/auth/me');
//      response.then((res) => {
//	console.log(res);
//	this.$router.push("/");
//      });
    }
  },
};
</script>

