import { createRouter, createWebHistory } from 'vue-router';
import DashBoard from '../components/DashBoard.vue';
import LogIn from '../components/LogIn.vue';
import WebDemo from '../components/WebDemo.vue';
import UserInfo from '../components/UserInfo.vue';

const routes = [
  {
    path: '/',
    name: 'DashBoard',
    meta: { requiresAuth: true },
    component: DashBoard
  },
  {
    path: '/user',
    name: 'UserInfo',
    meta: { requiresAuth: true },
    component: UserInfo
  },
  {
    path: '/login',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/webdemo',
    name: 'WebDemo',
    component: WebDemo
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
