<template>
<div id="container">
  <h3 class="state">{{state}}</h3>
    <div class="floating-btns-container">
      <button class="btn btn-primary floating-btn">
        <i class="fa-solid fa-expand"></i>
      </button>
      <button class="btn btn-primary floating-btn">
    <i class="fa-solid fa-volume-xmark"></i>
      </button>
    </div>
  <div class='joystick' id="joystick">
  </div>
  <video ref="video" class="video" playsinline>
    <source src=http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4 type=video/mp4>
  </video>
</div>

</template>

<script setup>
import { ref, onMounted } from 'vue'
import mqtt from 'mqtt'
import nipplejs from 'nipplejs'
//import { greet, add } from '../lib/peer.js';

const video = ref(null)
const state = ref(null)

var playerConfig = JSON.parse(localStorage.getItem('pp'))
if (playerConfig == null) {
  playerConfig = {}
  playerConfig.mqttUrl = "wss://broker.emqx.io:8084/mqtt"
  playerConfig.deviceId = "test"
  playerConfig.username = ""
  playerConfig.password = ""
  playerConfig.rotate = false
}

const deviceId = playerConfig.deviceId
const client = mqtt.connect(playerConfig.mqttUrl, {
  clientId: playerConfig.clientId,
  username: playerConfig.username,
  password: playerConfig.password,
})

var offerId = Math.floor((Math.random() * 1000) + 1);
var answerId = Math.floor((Math.random() * 1000) + 1);
var isMuted = true
var dcOpened = false

client.on('connect', () => {
  console.log("connected")
  state.value = 'Connecting.'
  client.subscribe('webrtc/' + deviceId + '/jsonrpc-reply', function (err) {
    if (!err) {
      console.log('subscribe ok')
    }
  })

  client.publish('webrtc/' + deviceId + '/jsonrpc', JSON.stringify({
   jsonrpc: '2.0',
   method: 'offer',
   id: offerId,
  }), {qos: 2})
})

client.on('message', function (topic, message) {

  let msg = JSON.parse(message.toString())
  console.log(msg)

  if (msg.error != undefined) {
    console.log(msg.error)
    state.value = msg.error.message
  } else if (msg.id == offerId) {

    state.value = 'Connecting..'
    let sdp = msg.result;
    let offer = {type: 'offer', sdp: sdp};
    console.log(offer)
    pc.setRemoteDescription(offer);
    pc.createAnswer().then(d => pc.setLocalDescription(d)).catch(() => console.log('error'));

    setTimeout(() => {
      let json = {
        jsonrpc: '2.0',
        method: 'answer',
        params: pc.localDescription.sdp,
        id: answerId,
      }
      console.log(json)
      client.publish('webrtc/' + deviceId + '/jsonrpc', JSON.stringify(json))
    }, 1000)

  } else if (msg.id == answerId) {

    console.log('receive answer ok')
  }
})


var pc = new RTCPeerConnection({
  iceServers: [
    {
      urls: "stun:stun.l.google.com:19302",
    },
  ],
});

const datachannel = pc.createDataChannel('libpeer')
pc.ontrack = (event) => {
  console.log(event.track.kind)
  if (event.track.kind == 'video') {
    video.value.srcObject = event.streams[0];
    video.value.muted = true;
    video.value.autoplay = true;
    console.log(video.value);
  } else if (event.track.kind == 'audio') {
    console.log('audio')
  }
}

pc.oniceconnectionstatechange = e => {
  console.log(pc.iceConnectionState)
  if (pc.iceConnectionState == 'checking') {
    state.value = 'Connecting...'
  } else if (pc.iceConnectionState == 'connected') {
    state.value = ''
  } else if (pc.iceConnectionState == 'disconnected') {
    state.value = 'Disconnected'
  } else if (pc.iceConnectionState == 'failed') {
    state.value = 'Failed'
  }
  console.log(e)
}

pc.ondatachannel = () => {
  console.log('ondatachannel');
}

datachannel.onclose = () => console.log('datachannel has closed');
datachannel.onopen = () => {
  dcOpened = true;
}

datachannel.onmessage = e => {
  console.log(e.data)
}

pc.onicecandidate = event => {
  console.log(event.candidate)
}

pc.addTransceiver('video', {direction: 'recvonly'});

const roundTo = function( num, decimal ) { return Math.round( ( num + Number.EPSILON ) * Math.pow( 10, decimal ) ) / Math.pow( 10, decimal ); }

onMounted(() => {
  if (playerConfig.rotate) {
    video.value.style.transform = 'translateY(-50%) rotate(180deg)';
  }

  var joystick = nipplejs.create({
    zone: document.getElementById('joystick'),
    mode: 'static',
    color: 'white',
    size: 100,
    position: { left: '20%', top: '20%' },
  });
  joystick.on('start end', function(evt) {
    console.log(evt.type, 0, 0);
    if (dcOpened) {
      datachannel.send(JSON.stringify({x: 0, y: 0}))
    }
  })
  joystick.on('move', function(evt, data) {
    console.log(evt.type, roundTo(data.vector.x, 2), roundTo(data.vector.y, 2));
    if (dcOpened) {
      datachannel.send(JSON.stringify({x: roundTo(data.vector.x, 2), y: roundTo(data.vector.y, 2)}))
    }
  })

  const setup = document.getElementById('btn-setup')
  setup.addEventListener('click', () => {
    document.getElementById('setup-dialog').showModal();
  });

  const setupEdit = document.getElementById('btn-setup-edit');
  const setupClose = document.getElementById('btn-setup-close');
  const setupSave = document.getElementById('btn-setup-save');
  const configText = document.getElementById('config');
  configText.value = JSON.stringify(playerConfig, null, 2);
  configText.disabled = true
  setupSave.style.display = 'none'

  setupEdit.addEventListener('click', () => {
    configText.disabled = false
    setupEdit.style.display = 'none'
    setupSave.style.display = 'inline-block'
  });

  setupSave.addEventListener('click', () => {

    try {
      playerConfig = JSON.parse(document.getElementById('config').value)
      localStorage.setItem('pp', JSON.stringify(playerConfig));
      configText.value = JSON.stringify(playerConfig, null, 2);
      configText.disabled = true
      setupSave.style.display = 'none'
      setupEdit.style.display = 'inline-block'
    } catch {
      console.log('not json format')
    }

  });

  setupClose.addEventListener('click', () => {
    document.getElementById('setup-dialog').close();
  });

  var fs = document.getElementById('btn-fs');
  var mute = document.getElementById('btn-mute');
  fs.addEventListener('click', goFullScreen);
  mute.addEventListener('click', () => {
    isMuted = !isMuted
    video.value.muted = isMuted
    if (isMuted) {
      document.getElementById('volume-high').style.display = 'none'
      document.getElementById('volume-xmark').style.display = 'block'
    } else {
      document.getElementById('volume-high').style.display = 'block'
      document.getElementById('volume-xmark').style.display = 'none'
    }
  })
})

function goFullScreen() {
  var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement;
  if (fullscreenElement) {
    exitFullscreen();
  } else {
    launchIntoFullscreen(document.getElementById('container'));
  }
}

function launchIntoFullscreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  } else if (video.value.webkitEnterFullScreen) {
    video.value.webkitEnterFullScreen();
  }
}

function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
}

</script>

<style scoped>

#container {
  width: 100%;
  height: 100%;
}

.btn-mute {
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  width: 25px;
  height: 25px;
  padding: 10px;
  border-radius: 15px;
  z-index: 10;
  cursor: pointer;
  bottom: 40px;
  left: 60px;
}

.btn-setup {
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  width: 25px;
  height: 25px;
  padding: 10px;
  border-radius: 15px;
  z-index: 10;
  cursor: pointer;
  bottom: 40px;
  left: 110px;
}

.btn {
  background: rgba(255, 255, 255, 0.3);
  width: 17px;
  height: 17px;
  padding: 10px;
  color: white;
  border-radius: 15px;
  cursor: pointer;
  display: inline-block
}

.btn-fs {
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 10px;
  color: white;
  border-radius: 15px;
  z-index: 10;
  cursor: pointer;
  bottom: 40px;
  left: 10px;
}

.joystick {
  position: absolute;
  bottom: 70px;
  right: 70px;
  height: 1%;
  width: 1%;
}

:-webkit-full-screen video {
  width: 100%;
  height: 100%;
}

.video {
  position: fixed;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.state {
  position: absolute;
  color: white;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.floating-btns-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px; /* 調整按鈕之間的距離 */
  z-index: 1000;
}

.floating-btn {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
