<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 mb-4">
        <div class="card">
          <div class="card-body">
            <!--h5 class="card-title d-flex align-items-center mb-3">
              <i class="fa-solid fa-robot"></i>
              <span>{{ name }}</span>
              <span class="badge bg-light ms-3">
                <i class="fas fa-check-circle me-1"></i>Online
              </span>
              <span
                class="badge ms-2"
                :class="isPushing ? 'bg-info' : 'bg-secondary'"
              >
                <i
                  :class="isPushing ? 'fas fa-video' : 'fas fa-pause-circle'"
                  class="me-1"
                ></i>
                {{ isPushing ? 'Streaming' : 'Idle' }}
              </span>
            </h5-->

            <div class="d-flex align-items-center mb-2">
              <div class="w-25">
                <label class="me-2">Signaling</label>
              </div>
              <input
                type="text"
                class="form-control form-control-sm bg-secondary text-light border-0 me-2"
                :value="signaling"
                readonly
              />
              <div class="w-25">
              <button
                class="btn btn-sm btn-outline-light me-2"
                @click="copyToClipboard(signaling)"
              >
                <i class="fas fa-copy"></i>
              </button>
              <button
                class="btn btn-sm btn-outline-light"
                @click="goToDemo(signaling_demo)"
              >
                <i class="fas fa-external-link-alt"></i>
              </button>
            </div>
            </div>

            <div class="d-flex align-items-center mb-2">
              <div class="w-25">
                <label class="me-2">SFU</label>
              </div>
              <input
                type="text"
                class="form-control form-control-sm bg-secondary text-light border-0 me-2"
                :value="sfu"
                readonly
              />
              <div class="w-25">
              <button
                class="btn btn-sm btn-outline-light me-2"
                @click="copyToClipboard(sfu)"
              >
                <i class="fas fa-copy"></i>
              </button>
              <button
                class="btn btn-sm btn-outline-light"
                @click="goToDemo(sfu_demo)"
              >
                <i class="fas fa-external-link-alt"></i>
              </button>
            </div>
            </div>

            <div class="d-flex align-items-center mb-2">
              <div class="w-25">
                <label class="me-2">Token</label>
              </div>
              <input
                type="text"
                class="form-control form-control-sm bg-secondary text-light border-0 me-2"
                :value="token"
                readonly
              />
              <div class="w-25">
              <button
                class="btn btn-sm btn-outline-light me-2"
                @click="copyToClipboard(token)"
              >
                <i class="fas fa-copy"></i>
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    <button class="btn btn-outline-light position-fixed bottom-0 end-0 m-3 rounded-circle" @click="logout">
      <i class="fas fa-sign-out-alt"></i>
    </button>
</template>

<script>
import backend from '@/backend';
export default {
  data() {
    return {
      name: '',
      signaling: 'mqtts://libpeer.com/signaling',
      signaling_demo: 'https://libpeer.com/demo',
      sfu: 'https://libpeer.com/whip/test',
      sfu_demo: 'https://libpeer.com/demo',
      token: '1wxzcbd=edxcb',
      isPushing: true,
    };
  },
  created() {
    backend.get('/api/me').then((res) => {
      if (res.data) {
        console.log(res.data);
        const username = res.data.username;
        this.token = res.data.token;
        this.signaling = 'mqtts://libpeer.com/signaling/' + username;
        this.sfu = 'https://libpeer.com/sfu/' + username;
        this.sfu_demo = '/demo?url=' + btoa(this.sfu) + '&token=' + this.token;
        this.signaling_demo = '/demo?url=' + btoa(this.signaling) + '&token=' + this.token;
      }
    });
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => alert('Copied to clipboard!'),
        () => alert('Copy failed, please try again!')
      );
    },
    goToDemo(url) {
      console.log(url);
      window.open(url, '_blank');
    },
    async logout() {
      try {
        await backend.post('/auth/logout');
        this.$router.push("/login");
      } catch (error) {
        console.error("Logout failed:", error);
      }
    }
  },
};
</script>
